import React, { useEffect, useRef } from 'react'
import BookingInfo from './Components/BookingInfo'
import { useSelector } from 'react-redux'
import {
    $, CompanyRoutes,
    queryString, showMessage,
    urlConfigs,
    useActionUrl,
    useLocation,
    useState
} from '../../library/base/baseContainerImports'
import { useHistory } from 'react-router-dom'
import CouponForm from './Components/CouponForm'
import { formatter } from './Components/BookingForm'
import TermsAndConditionsForm from './Components/TermsAndConditionsForm'
import { loadStripe } from '@stripe/stripe-js'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import Loading from './Components/Loading';
import { PaymentMethods } from "constants/paymentMethods";

const Checkout = () => {
    const history = useHistory();
    const location = useLocation();
    const {getFullUrl} = useActionUrl();
    const ref = useRef();
    const checkoutData = JSON.parse(localStorage.getItem('checkoutData'));
    const [pricing, setPricing] = useState(checkoutData.pricing);
    const userToken = useSelector(state => state.auth.token);
    const {authhash, b} = queryString.parse(location.search);
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [loadingNSWCoupon, setLoadingNSWCoupon] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingChildren, setLoadingChildren] = useState(false);
    const [acceptTermAndCondition, setAcceptTermAndCondition] = useState(false);
    const [coupon, setCoupon] = useState({});
    const [stripePublicKey, setStripePublicKey] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const [userMeta, setUserMeta] = useState({});
    const [children, setChildren] = useState([]);
    const [termContent, setTermContent] = useState('');
    const [selectedChildren, setSelectedChildren] = useState([]);
    const [shouldCalculate, setShouldCalculate] = useState(false);
    const [insurances, setInsurances] = useState(null);
    const [insuranceForm, setInsuranceForm] = useState([]);
    const [event, setEvent] = useState({});

    const addCoupon = (code) => {
        setLoadingCoupon(true);
        const insuranceQueryString = buildQueryStudent();

        const formAction = getFullUrl(
            "/api/v1/calculate-charges-summary",
            {
                authhash,
                event_id: checkoutData.event_id,
                quantity: checkoutData.quantity,
                booked: checkoutData.booked,
                type_of_registration: 'event',
                coupon_code: code
            }
        ) + insuranceQueryString;
        $.get(formAction, function (data) {
            setPricing(data);
            $(".form-coupon").find('.nNote').remove();
            if (data.errors.invalid_coupon_code_message)
                showMessage ( $(".form-coupon") , data.errors.invalid_coupon_code_message);
            setLoadingCoupon(false);
        }).fail(() => {
            setLoadingCoupon(false);
        });
    }

    const addNSWCoupon = (code) => {
        setLoadingNSWCoupon(true);
        const formAction = getFullUrl(
            "/api/v1/calculate-charges-summary",
            {
                authhash,
                date: checkoutData.date,
                quantity: checkoutData.quantity,
                type_of_registration: 'party',
                nsw_coupon_code: code
            }
        )
        $.get(formAction, function (data) {
            setPricing(data);
            $(".form-nsw-coupon").find('.nNote').remove();
            if (data.errors.invalid_nsw_coupon_code_message)
                showMessage ( $(".form-nsw-coupon") , data.errors.invalid_nsw_coupon_code_message);
            setLoadingNSWCoupon(false);
        }).fail(() => {
            setLoadingNSWCoupon(false);
        });
    }

    const buildQueryStudent = () => {
        let insuranceQueryString = '';
        selectedChildren.forEach((child, index) => {
            insuranceQueryString += `students[${index}][insurance_type]=${child.insurance_type}&`;
            insuranceQueryString += `students[${index}][insurance_ids]=${child.insurance_ids.join(',')}&`;
        });
        return insuranceQueryString;
    }

    const changeInsurance = () => {
        setLoading(true);
        const insuranceQueryString = buildQueryStudent();

        const formAction = getFullUrl(
            "/api/v1/calculate-charges-summary",
            {
                authhash,
                event_id: checkoutData.event_id,
                quantity: checkoutData.quantity,
                booked: checkoutData.booked,
                type_of_registration: 'event',
                coupon_code: pricing.additional_information.coupon_code,
            }
        ) + insuranceQueryString;
        $.get(formAction, function (data) {
            setPricing(data);
            setLoading(false);
        }).fail(() => {
            setLoading(false);
        });
    }

    const companyBranding = () => {
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data?.meta?.logo) {
                setCompanyLogo(data.meta.logo);
            }
        });
    };

    const getEvent = () => {
        const eventAction = getFullUrl("/api/v1/booking", {e: checkoutData.event_id, authhash: authhash});
        $.get(eventAction, function (data) {
            setEvent(data);
            return false;
        });
    }

    const getChildren = () => {
        setLoadingChildren(true);
        const childrenAction = getFullUrl(CompanyRoutes.ADMIN.STUDENTS.PROFILE.CHILDREN.path);
        $.get(childrenAction, function (data) {
            if(Array.isArray(data)) {
                setChildren(data);
                setLoadingChildren(false);
            }
            return false;
        });
    }

    const getTermContent = () => {
        const termContentAction = getFullUrl("/api/v1/booking/payment-terms", {event_id: checkoutData.event_id, authhash: authhash});
        $.get(termContentAction, function ({ content }) {
            setTermContent(content);
            return false;
        });
    }

    const getInsurance = () => {
        setLoadingChildren(true);
        const insuranceAction = getFullUrl("/api/v1/students/events/insurances", {event_id: checkoutData.event_id});
        $.get(insuranceAction, function (data) {
            setInsurances(data);
            setLoadingChildren(false);
            return false;
        });
    }

    const fetchUserMeta = () => {
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data) {
                setUserMeta({
                    full_name: data.name,
                    ...data.meta
                });
            }
        });
    }

    const validateChildSelection = () => {
        const existingIds = selectedChildren.filter(child => child.id != 0  ).map(child => child.id);

        const idSet = new Set(existingIds);

        if (idSet.size !== existingIds.length) {
            showMessage ( $(".form-checkout") , "Each existing child must be unique." );
            ref.current.scrollIntoView({ behavior: 'smooth' });
            return false;
        }

        const dobFormat = /^\d{4}-\d{2}-\d{2}$/;

        let isValid = true;

        selectedChildren.forEach((child, index) => {
            if (child.id == 0) {
                if (!child.first_name || !child.last_name || !child.dob || !child.dob) {
                    showMessage ( $(".form-checkout") , `New child at position ${index + 1} must have both first name, last name and date of birth.` );
                    ref.current.scrollIntoView({ behavior: 'smooth' });
                    isValid = false;
                    return false;
                }

                if (!dobFormat.test(child.dob)) {
                    showMessage ( $(".form-checkout") , `New child at position ${index + 1} has a date of birth invalid.` );
                    ref.current.scrollIntoView({ behavior: 'smooth' });
                    isValid = false;
                    return false;
                }
            }

            if (checkoutData.booked.length > 0) {
                if (child.id == 0) {
                    if (!child.insurance_type) {
                        showMessage ( $(".form-checkout") , `Please select a insurance.` );
                        ref.current.scrollIntoView({ behavior: 'smooth' });
                        isValid = false;
                        return false;
                    }
                } else {
                    if (!child.insurance_type && insurances[child.id].insurance_ids.length > 0) {
                        showMessage ( $(".form-checkout") , `Please select a insurance.` );
                        ref.current.scrollIntoView({ behavior: 'smooth' });
                        isValid = false;
                        return false;
                    }
                }

                if (child.insurance_type === 'full_insurance' && child.insurance_ids.length === 0) {
                    showMessage ( $(".form-checkout") , `Please select a insurance.` );
                    ref.current.scrollIntoView({ behavior: 'smooth' });
                    isValid = false;
                    return false;
                }
            }

        });

        return isValid;
    };

    const handleCheckout = () => {


        if (!validateChildSelection()) return false;
        if (!acceptTermAndCondition) {
            showMessage ( $(".form-checkout") , "You need to accept our Terms and Conditions before placing this booking" );
            ref.current.scrollIntoView({ behavior: 'smooth' });
            return false;
        }

        setLoading(true);

        const formData = {
            booking_fee: pricing.pricing_details.booking_fee.value,
            amount: pricing.pricing_details.total.value,
            surcharge: pricing.pricing_details.surcharge.value,
            insurance: pricing.pricing_details.insurance.value,
            discount_amount: pricing?.pricing_details?.coupon?.value || 0,
            discount_reason: "Coupon Code",
            coupon_code: pricing.additional_information.coupon_code ?? null,
            children: selectedChildren,
            booking_hash: b || false,
            ...checkoutData
        }
        const checkoutUrl = getFullUrl('/api/v1/booking/checkout', {authhash});

        let stripeCheckoutSessionId = null;

        $.post(checkoutUrl, formData, function (data) {
            if (data.public_key && data.client_secret && data.sessionId) {
                setStripePublicKey(loadStripe(data.public_key));
                setClientSecret(data.client_secret);
                stripeCheckoutSessionId = data.sessionId;
                $('#stripeCheckout').modal('show');

                $('#stripeCheckout').on('hidden.bs.modal', function (e) {
                    let cancelAction = getFullUrl('/integrations/stripe/cancel-session', {
                        session: stripeCheckoutSessionId
                    });
                    $.post(cancelAction, {userAction: 'expire'}, function (data) {
                        return false;
                    });
                    return true;
                });

            } else {
                if (data.valid) {
                    if(data.method === PaymentMethods.SQUARE){
                         window.location.href = data.payment_link;
                    }else{
                        history.push(`/booking?authhash=${authhash}&status=true`);
                    }
                }else {
                    showMessage ( $(".form-checkout") , "Something Wrong!" );
                    ref.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
            setLoading(false);
        }).fail(function (jqXHR, status, a) {
            let errorMessage = "Something went wrong!";

            // Check if the response contains JSON data
            if (jqXHR.responseJSON) {
                // Extract and format error message from JSON response
                errorMessage = jqXHR.responseJSON.message || errorMessage;
            } else if (jqXHR.responseText) {
                try {
                    // Attempt to parse responseText as JSON
                    const response = JSON.parse(jqXHR.responseText);
                    errorMessage = response.message || errorMessage;
                } catch (e) {
                    // If responseText is not JSON, use it as is
                    errorMessage = jqXHR.responseText;
                }
            }

            showMessage($(".form-checkout"), errorMessage);
            ref.current.scrollIntoView({ behavior: 'smooth' });
            setLoading(false);
        })
    }

    useEffect(() => {
        if (!userToken) {
            const checkoutPath = `/booking/checkout?authhash=${authhash}`;
            const checkoutUrl = urlConfigs.LOCAL_URL.replace('/admin/', '') + checkoutPath
            history.push(`admin/students/login?authhash=${authhash}&redirect=${checkoutUrl}`);
        }

        companyBranding();
        getTermContent();
        getEvent();
    }, []);

    useEffect(() => {
        if (companyLogo) {
            fetchUserMeta();
            getChildren();
            getInsurance();
        }
    },[companyLogo]);

    useEffect(() => {

        const initialSelectedChildren = Array.from({ length: checkoutData.quantity }).map((_, index) => {
            return children[index]
                ? { id: children[index].id, consent: 0, insurance_type: null, insurance_ids: [] }
                : { id: 0, first_name: '', last_name: '', dob: '', consent: 0, insurance_type: null, insurance_ids: [] };
        });

        setSelectedChildren(initialSelectedChildren);
    }, [children, checkoutData.quantity]);

    useEffect(() => {
        let form = [];
        selectedChildren.forEach(function (child, index) {
            form[index] = renderInsuranceForm(child, index);
        })
        setInsuranceForm(form);
    }, [selectedChildren, insurances]);

    useEffect(() => {
        changeInsurance();
    }, [shouldCalculate])

    const handleChildChange = (index, value, field) => {
        const updatedSelectedChildren = [...selectedChildren];
        updatedSelectedChildren[index][field] = value;
        setSelectedChildren(updatedSelectedChildren);

        if (field === 'insurance_type' || field === 'id') {
            setShouldCalculate(!shouldCalculate);
            const insurance_ids = selectedChildren[index].id != 0 ? insurances[selectedChildren[index].id].insurance_ids : insurances['full'].insurance_ids
            handleChildChange(index, insurance_ids, 'insurance_ids')

            if (field === 'id') handleChildChange(index, null, 'insurance_type')
        }
    };

    const renderInsuranceForm = (child, index) => {
        if (checkoutData.booked.length === 0) {
            return <></>;
        }

        if (child.id != 0 && insurances) {
            if (insurances[child.id]?.insurance_ids?.length === 0) {
                return <p className="text-start">Already purchased insurance.</p>;
            }
        }

        return (
            <>
                {event?.daily_insurance_id && <div className="form-check">
                    <input className="form-check-input mr-5" type="radio" value="daily_insurance"
                           checked={child.insurance_type === 'daily_insurance'}
                           name={`insurance-${index}`}
                           onChange={(e) => handleChildChange(index, e.target.value, 'insurance_type')}
                           id={`daily-insurance-${index}`}/>
                    <label className="form-check-label" htmlFor={`daily-insurance-${index}`}>
                        Purchase Daily Insurance
                    </label>
                </div>}
                <div className="form-check">
                    <input className="form-check-input mr-5" type="radio" value="full_insurance"
                           checked={child.insurance_type === 'full_insurance'}
                           name={`insurance-${index}`}
                           onChange={(e) => handleChildChange(index, e.target.value, 'insurance_type')}
                           id={`full-insurance-${index}`}/>
                    <label className="form-check-label" htmlFor={`full-insurance-${index}`}>
                        Purchase full insurance
                    </label>
                </div>
            </>
        )
    }

    const renderChildren = () => {
        return selectedChildren.map((selectedChild, qtyIndex) => {
            return (
                <div key={qtyIndex} className="col-md-12 mb-20">
                    <div>
                        <h6>Child {qtyIndex + 1}.</h6>
                    </div>
                    <div>
                        <div className="mb-10 col-md-6">
                            <label>Child</label>
                            <select
                                className="form-control"
                                value={selectedChild.id}
                                onChange={(e) => handleChildChange(qtyIndex, e.target.value, 'id')}
                            >
                                <option key={qtyIndex} value={0}>Add new</option>
                                {children.map(child => (
                                    <option key={child.id} value={child.id}>
                                        {child.first_name} {child.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-10 col-md-6">
                            <label>Photos for promotional materials/social media</label>
                            <select
                                className="form-control"
                                defaultValue={'0'}
                                onChange={(e) => handleChildChange(qtyIndex, e.target.value, 'consent')}
                            >
                                <option value="0">No - I do not consent</option>
                                <option value="1">Yes - I consent</option>
                            </select>
                        </div>
                        {
                            selectedChild?.id == 0 && (
                                <div>
                                    <div className="col-md-6 mb-10">
                                        <label>Child's First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter new child's first name"
                                            onChange={(e) => handleChildChange(qtyIndex, e.target.value, 'first_name')}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-10">
                                        <label>Child's Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter new child's last name"
                                            onChange={(e) => handleChildChange(qtyIndex, e.target.value, 'last_name')}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-10">
                                        <label>Child's Date of Birth</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            placeholder="Enter new child's date of birth"
                                            onChange={(e) => handleChildChange(qtyIndex, e.target.value, 'dob')}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className="mt-10 col-md-12">
                        {insuranceForm[qtyIndex]}
                    </div>

                </div>
            )
        });
    }

    return (
        <div className="booking-event">
            <div className="container">
                <div className=" border-bottom border-color-light-grey pb-10 mb-10">
                    <div className="display-block text-slate-300 text-center">
                        {companyLogo && <img alt="Logo" src={companyLogo}/>}
                    </div>
                    {userToken && <a href={CompanyRoutes.ADMIN.STUDENTS.PROFILE.path}><i
                        className="icon-arrow-left7"></i> Go
                        to my account</a>}
                </div>
                <div className="row mb-20 form-checkout" ref={ref}>
                    {loadingChildren ? (
                        <div className="text-center">Loading Children <Loading/></div>) : renderChildren()}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <CouponForm
                            addCoupon={addCoupon}
                            loading={loadingCoupon}
                            coupon={pricing.additional_information.coupon_code || ''}
                            addNSWCoupon={addNSWCoupon}
                            nswLoading={loadingNSWCoupon}
                            nswCoupon={pricing.additional_information.nsw_coupon_code || ''}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <TermsAndConditionsForm termContent={termContent}
                                                setAcceptTermAndCondition={setAcceptTermAndCondition}
                                                acceptTermAndCondition={acceptTermAndCondition}/>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                        <BookingInfo checkoutData={checkoutData} userData={userMeta}/>
                    </div>
                </div>
                {
                    Object.keys(pricing.pricing_details).map(( key) => {
                        const item = pricing.pricing_details[key];
                        return (
                            <div className="row highlighted-row">
                                <div className="col-sm-6 hidden-xs">
                                    <h5>{item.label}</h5>
                                    {item.description && (
                                        <small>{item.description}</small>
                                    )}
                                </div>
                                <div className="col-sm-6 " align="right">
                                    <h5>{item.formatted}</h5>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="row pull-right">
                    <div className="col-md-12 mt-10 no-padding-left no-padding-right">
                        <button
                            type="button"
                            onClick={handleCheckout}
                            className="btn btn-primary"
                            disabled={loading || loadingCoupon || loadingChildren}
                        >Confirm Payment
                        </button>
                    </div>
                </div>

            </div>
            {clientSecret && <div class="modal fade" id="stripeCheckout" tabindex="-1" role="dialog"
                                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Stripe Checkout</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div id="checkout">
                                <EmbeddedCheckoutProvider
                                    stripe={stripePublicKey}
                                    options={{ clientSecret }}
                                >
                                    <EmbeddedCheckout/>
                                </EmbeddedCheckoutProvider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}
export default Checkout;
